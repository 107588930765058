import component_request_pb from "@skydio/pbtypes/pbtypes/gen/scan_planner/component_request_pb";
import polygon_prism_pb from "@skydio/pbtypes/pbtypes/gen/scan_planner/polygon_prism_pb";
import scan_data_segment_pb from "@skydio/pbtypes/pbtypes/gen/scan_output/scan_data_segment_pb";
import scan_mesh_pb from "@skydio/pbtypes/pbtypes/gen/scan_output/scan_mesh_pb";
import scan_output_pb from "@skydio/pbtypes/pbtypes/gen/scan_output/scan_output_pb";
import scan_photo_image_pb from "@skydio/pbtypes/pbtypes/gen/scan_output/scan_photo_image_pb";
import inspection_type_pb from "@skydio/pbtypes/pbtypes/gen/surface_scan/inspection_type_pb";

import type { XY, XYZW, V2, V3 } from "../../utils/types";

import type {
  CameraViewMeta,
  CameraViewMetas,
  FlightMetas,
  ImageMetas,
  ModelMeta,
  ModelMetas,
  ScanMeta,
  ScanOutput,
  VolumeIntent,
} from "./types";

/**
 * The scan_photo_status_pb.ScanPhotoStatus.Enum values that should be ignored/removed.
 * TODO(rachel): convert the json strings in ScanOutput into normal fields so they can be
 * used more easily. https://skydio.atlassian.net/browse/SW-28588
 */
const photoStatusesToIgnore = new Set(["TIMED_OUT", "USER_SKIPPED"]);
const photoExtensionsToIgnore = new Set(["dng", "raw"]);

/**
 * Convert scan_output model type to standardized enum.
 */
const BINARY_COVERAGE_ALIASES = new Set(["binary_coverage", "binary coverage"]);
const CLOUD_PHOTOGRAMMETRY_ALIASES = new Set(["cloud photogrammetry"]);
const COVERAGE_HEATMAP_ALIASES = new Set(["coverage_heatmap", "num photos"]);
const COVERAGE_WITHIN_PARAMS_ALIASES = new Set([
  "coverage_within_params",
  "num photos within parameters",
]);
const COVERAGE_WITH_ANGULAR_SPREAD_HEATMAP_ALIASES = new Set([
  "coverage_with_angular_spread_heatmap",
  "num photos with angular spread",
]);
const GROUND_PLANE_ALIASES = new Set(["num photos on ground plane"]);
const DTED_COVERAGE_ALIASES = new Set(["num photos on dted mesh"]);
const INVALID_ALIASES = new Set(["invalid"]);
const VEHICLE_ORTHOMAP_CO_ALIASES = new Set(["vehicle orthomap pyramid"]);
const VEHICLE_ORTHOMAP_NCO_ALIASES = new Set(["vehicle orthomap"]);
const VEHICLE_PHOTOGRAMMETRY_ALIASES = new Set(["vehicle photogrammetry"]);
export enum ModelType {
  BINARY_COVERAGE = "3D Binary Coverage",
  CLOUD_PHOTOGRAMMETRY = "Cloud Model",
  COVERAGE_HEATMAP = "3D Heatmap Coverage",
  COVERAGE_WITHIN_PARAMS = "3D Coverage",
  COVERAGE_WITH_ANGULAR_SPREAD_HEATMAP = "3D Angular Spread Heatmap Coverage",
  GROUND_PLANE = "2D Coverage",
  DTED_COVERAGE = "DTED Coverage",
  INVALID = "Invalid",
  VEHICLE_CO_ORTHOMAP = "Onboard Map",
  VEHICLE_NCO_ORTHOMAP = "Onboard Map (Flat)",
  VEHICLE_PHOTOGRAMMETRY = "Onboard Model",
  UNKNOWN = "Unknown",
}
export const orthomapModelTypes = new Set([
  ModelType.VEHICLE_CO_ORTHOMAP,
  ModelType.VEHICLE_NCO_ORTHOMAP,
]);
const getModelType = (string: string) => {
  const lowerString = string.toLowerCase();

  let type = ModelType.UNKNOWN;
  if (BINARY_COVERAGE_ALIASES.has(lowerString)) {
    type = ModelType.BINARY_COVERAGE;
  } else if (CLOUD_PHOTOGRAMMETRY_ALIASES.has(lowerString)) {
    type = ModelType.CLOUD_PHOTOGRAMMETRY;
  } else if (COVERAGE_HEATMAP_ALIASES.has(lowerString)) {
    type = ModelType.COVERAGE_HEATMAP;
  } else if (COVERAGE_WITHIN_PARAMS_ALIASES.has(lowerString)) {
    type = ModelType.COVERAGE_WITHIN_PARAMS;
  } else if (COVERAGE_WITH_ANGULAR_SPREAD_HEATMAP_ALIASES.has(lowerString)) {
    type = ModelType.COVERAGE_WITH_ANGULAR_SPREAD_HEATMAP;
  } else if (GROUND_PLANE_ALIASES.has(lowerString)) {
    type = ModelType.GROUND_PLANE;
  } else if (DTED_COVERAGE_ALIASES.has(lowerString)) {
    type = ModelType.DTED_COVERAGE;
  } else if (VEHICLE_ORTHOMAP_CO_ALIASES.has(lowerString)) {
    type = ModelType.VEHICLE_CO_ORTHOMAP;
  } else if (VEHICLE_ORTHOMAP_NCO_ALIASES.has(lowerString)) {
    type = ModelType.VEHICLE_NCO_ORTHOMAP;
  } else if (VEHICLE_PHOTOGRAMMETRY_ALIASES.has(lowerString)) {
    type = ModelType.VEHICLE_PHOTOGRAMMETRY;
  } else if (INVALID_ALIASES.has(lowerString)) {
    type = ModelType.INVALID;
  }

  return type;
};

/** Descending preference for which model to autoload. */
export const modelTypePreference = {
  // Orthomaps
  [ModelType.VEHICLE_CO_ORTHOMAP]: 0,
  [ModelType.VEHICLE_NCO_ORTHOMAP]: 1,
  // Photogrammetry
  [ModelType.CLOUD_PHOTOGRAMMETRY]: 10,
  [ModelType.VEHICLE_PHOTOGRAMMETRY]: 11,
  // Coverage
  [ModelType.COVERAGE_WITHIN_PARAMS]: 20,
  [ModelType.GROUND_PLANE]: 21,
  [ModelType.COVERAGE_HEATMAP]: 22,
  [ModelType.COVERAGE_WITH_ANGULAR_SPREAD_HEATMAP]: 23,
  [ModelType.BINARY_COVERAGE]: 24,
  [ModelType.DTED_COVERAGE]: 25,
  // Misc
  [ModelType.UNKNOWN]: 30,
  [ModelType.INVALID]: 31,
};
export const sortModelsByTypePreference = (a: ModelMeta, b: ModelMeta) =>
  modelTypePreference[a.type] - modelTypePreference[b.type];

/**
 * Create uuids from what should be stable inputs. Ideally, these are temporary and will come
 * from API.
 * TODO(rachel, josiah): replace?
 */
const getCameraViewId = (flightId: string, utime: number) => `${flightId}__${utime}`;

/**
 * Get flight takeoff timestamp from CameraViewMetas. Return -1 if undefined.
 * TODO(rachel): can probably remove the undefined check when protobufs improve data validation.
 */
const getFlightTakeoffTimestamp = (cameraViewMetas: CameraViewMetas, flightMetas: FlightMetas) => {
  const flightId = Object.values(cameraViewMetas)[0]?.flightId ?? "";
  return flightMetas[flightId]?.takeoffUclock?.valueOf() ?? -1;
};

/**
 * Compare function to sort CameraViewMetas by utime, oldest first.
 */
const sortCameraViewMetasByUtimeOldestFirst = (a: CameraViewMeta, b: CameraViewMeta) =>
  a.utime - b.utime;

/**
 * Convert a list of protobuf ScanPhotoImage into ImageMetas.
 */
const photoImageListToImageMetas = (imageList: scan_photo_image_pb.ScanPhotoImage.AsObject[]) => {
  // We don't want to ignore the camera view if the source imageList is empty or has an
  // unknown issue. We want to display error/info messages in those cases so that issues are
  // easier to recognize and debug, for users can devs. We should have some positive
  // reason to set ignoreCameraView true.
  let ignoreCameraView = false;
  const imageMetas = {} as ImageMetas;
  for (const image of imageList) {
    const imageId = image.mediaId;
    const size: [number, number] = (image.imageSize?.dataList as [number, number] | undefined) ?? [
      0, 0,
    ];

    const extension = image.path?.toLowerCase().split(".").pop() ?? "";

    if (!photoExtensionsToIgnore.has(extension)) {
      imageMetas[imageId] = {
        height: size[1],
        id: imageId,
        intrinsics: {
          distortionCoefficients: (image.intrinsics?.distortionCoefficientsList ?? []) as V3,
          distortionModel: image.intrinsics?.distortionModel,
          focalLength: (image.intrinsics?.focalLength?.dataList ?? []) as V2,
          principalPoint: (image.intrinsics?.principalPoint?.dataList ?? []) as V2,
        },
        path: image.path,
        width: size[0],
      };
    }
  }

  // Ignore the whole cameraView if all of its images were ignored.
  if (Object.keys(imageMetas).length === 0 && imageList.length > 0) {
    ignoreCameraView = true;
  }

  return { ignoreCameraView, imageMetas };
};

/**
 * Convert a list of protobuf ScanMesh into ModelMetas.
 */
export const meshListToMeshMetas = (meshes: scan_mesh_pb.ScanMesh.AsObject[]): ModelMetas => {
  return meshes.reduce(
    (acc, mesh) => ({
      ...acc,
      [mesh.meshId]: {
        boundingBox: {
          max: mesh.boundingBox?.max?.dataList! as V3,
          min: mesh.boundingBox?.min?.dataList! as V3,
        },
        hasNoOrientationCorrection: mesh.gltfRotationCorrectionOmitted,
        id: mesh.meshId,
        path: mesh.path,
        type: getModelType(mesh.type),
      },
    }),
    {} as ModelMetas
  );
};

/**
 * Convert photos from a protobuf ScanDataSegment into CameraViewMetas.
 */
const scanDataSegmentToCameraViews = (
  scanDataSegment: scan_data_segment_pb.ScanDataSegment.AsObject
): CameraViewMetas => {
  const flightId = scanDataSegment.flightId;
  return scanDataSegment.photosList.reduce((acc, photo): CameraViewMetas => {
    // Ignore any photos whose status is in photoStatusesToIgnore.
    // They should not be included as cameraViews at all.
    const extraFields = JSON.parse(photo.json);
    const statusString = extraFields.status;
    if (photoStatusesToIgnore.has(statusString)) {
      return acc;
    }

    const cameraViewId = getCameraViewId(flightId, photo.utime);
    const { imageMetas, ignoreCameraView } = photoImageListToImageMetas(photo.nativeImagesList);

    return ignoreCameraView
      ? acc
      : {
          ...acc,
          [cameraViewId]: {
            flightId,
            globalMapTCamera: {
              orientation: photo.globalMapTCamera?.orientation?.xyzwList! as XYZW,
              position: photo.globalMapTCamera?.position?.dataList! as V3,
              utime: photo.globalMapTCamera?.utime!,
            },
            id: cameraViewId,
            // TODO(rachel): also undistortedImages?
            imageMetas,
            uclock: new Date(photo.uclock / 1000),
            utime: photo.utime,
          },
        };
  }, {} as CameraViewMetas);
};

// Pull out top level scan metadata from the scan output
export const scanOutputToScanMeta = (scanOutput: scan_output_pb.ScanOutput.AsObject): ScanMeta => {
  return {
    created: new Date(scanOutput.creationUclock / 1000),
    description: scanOutput.description,
    id: scanOutput.scanSkillStateId,
    name: scanOutput.siteName || scanOutput.name,
  };
};

/**
 * Convert scanDataSegments into CameraViewMetas and FlightMetas.
 */
export const convertScanDataSegments = (
  segments: scan_data_segment_pb.ScanDataSegment.AsObject[]
) => {
  const cameraViewMetasArray = segments.map(scanDataSegment =>
    scanDataSegmentToCameraViews(scanDataSegment)
  );
  // merge the CameraViewMetas from each scanDataSegment into a single CameraViewMetas
  const cameraViewMetas = cameraViewMetasArray.reduce(
    (acc, partial) => ({ ...acc, ...partial }),
    {} as CameraViewMetas
  );

  const flightMetas: FlightMetas = segments.reduce(
    (acc, segment) => ({
      ...acc,
      // safe to overwrite existing
      [segment.flightId]: {
        id: segment.flightId,
        takeoffUclock: new Date(segment.takeoffUclock / 1000),
        vehicleId: segment.vehicleId,
        vehicleName: segment.vehicleName,
      },
    }),
    {} as FlightMetas
  );

  // sort cameraViewMetas by flight takeoff time
  const sortedCameraViewMetasArray = cameraViewMetasArray.sort(
    (a: CameraViewMetas, b: CameraViewMetas) =>
      getFlightTakeoffTimestamp(a, flightMetas) - getFlightTakeoffTimestamp(b, flightMetas)
  );
  // sort cameraView ids by flight utime
  // NOTE(rachel): for now, flatten cameraView ids into one list with oldest first, keeping order
  // within flight.
  let cameraViewsPath: string[] = [];
  for (const cameraViewMetas of sortedCameraViewMetasArray) {
    const sortedCameraViewMetas = Object.values(cameraViewMetas).sort(
      sortCameraViewMetasByUtimeOldestFirst
    );
    const cameraViewIds = sortedCameraViewMetas.map(cameraViewMeta => cameraViewMeta.id);
    cameraViewsPath.push(...cameraViewIds);
  }

  return { cameraViewMetas, cameraViewsPath, flightMetas };
};

/**
 * Get altitude of area scan or null if not available.
 */
export const getAreaScanAltitude = (
  componentSettingsList: component_request_pb.ComponentRequest.AsObject[]
) => {
  let altitude: number | null = null;

  for (const component of componentSettingsList) {
    if (component.inspectionType === inspection_type_pb.InspectionType.Enum.AREA) {
      const desiredRange = component.areaScanRequest?.scanCoverageParameters?.desiredRange ?? null;
      // if desired range is 0, it's the default, so don't use
      altitude = desiredRange === 0 ? null : desiredRange;
    }
  }

  return altitude;
};

/**
 * Convert volume intent.
 */
export const convertVolumeIntent = (
  volumeIntent: polygon_prism_pb.PolygonPrism.AsObject | null | undefined
): VolumeIntent =>
  volumeIntent
    ? {
        maxHeight: volumeIntent.maxHeight,
        minHeight: volumeIntent.minHeight,
        polygon: volumeIntent.polygon?.verticesList.map(vertex => vertex.dataList! as XY) ?? [],
      }
    : null;

/**
 * Map a ScanOutput protobuf into the correct form for the app state
 */
export const convertScanOutput = (scanOutput: scan_output_pb.ScanOutput.AsObject): ScanOutput => {
  const { cameraViewMetas, cameraViewsPath, flightMetas } = convertScanDataSegments(
    scanOutput.scanDataSegmentsList
  );
  const areaScanAltitude = getAreaScanAltitude(
    scanOutput.scanSettings?.componentSettingsList ?? []
  );

  return {
    ...scanOutputToScanMeta(scanOutput),
    areaScanAltitude,
    cameraViewMetas,
    cameraViewsPath,
    flightMetas,
    modelMaxPhotoCount:
      scanOutput.inFlightCoverageMeshRequest?.coverageMeshComponent?.maxNumPhotos ?? null,
    modelMetas: meshListToMeshMetas(scanOutput.meshesList),
    volumeIntent: convertVolumeIntent(scanOutput.volumeIntent),
  };
};
